<template>
  <v-container>
    <vue-snotify></vue-snotify>
    <v-card>
      <v-card-title class="cyan darken-1 card-sipymex">
        <v-row>
          <v-col cols="12" md="9">
            <span class="white--text">Servicios</span>
          </v-col>
          <v-col cols="12" md="3">
            <span class="m-20 float-right">
              <v-btn small color="white" :to="{ name: 'create-service' }">
                <v-icon small dark> mdi-plus-circle </v-icon> Agregar servicio
              </v-btn></span
            >
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="8"></v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <br />

        <v-data-table
          :headers="headers"
          :items="services"
          :search="search"
          :footer-props="{
            itemsPerPageText: 'Filas por página',
            itemsPerPageAllText: 'todos',
          }"
          no-data-text="No hay datos disponibles"
          no-results-text="No hay datos disponibles"
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon :to="'/update-service/' + item.id">
                  <v-icon v-bind="attrs" color="primary" v-on="on">
                    mdi-pencil-box-multiple
                  </v-icon>
                </v-btn>
              </template>
              <span>Modificar</span>
            </v-tooltip>

            <v-dialog transition="dialog-bottom-transition" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon v-bind="attrs" color="red darken-2" v-on="on">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="primary" dark>Mensaje</v-toolbar>
                  <v-card-text>
                    <div class="text-h7 pa-6">
                      ¿Estás seguro que deseas eliminar el servicio?
                    </div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="dialog.value = false">Cancelar</v-btn>
                    <v-btn
                      class="primary"
                      text
                      @click="
                        dialog.value = false;
                        deleteDay(item);
                      "
                      >Aceptar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data: () => ({
    dialog: false,
    search: "",
    headers: [
      { text: "Servicio", value: "name" },
      { text: "Descripción", value: "description" },
      { text: "Acciones", value: "actions" },
    ],
    services: [],
  }),

  computed: {},
  mounted() {
    this.action = "Eliminar";
    this.getServices();
  },
  watch: {},

  methods: {
    deleteDay(item) {
      console.log("eliminando");
      console.log(item);
      let request = {
        id: item.id,
      };
      axios
        .post("/destroy-service", request)
        .then((response) => {
          this.getServices();
          this.displayNotification("success", "Éxito", "Servicio eliminado con éxito");
          console.log(response.data);
          setTimeout(() => this.$router.push({ name: "services" }), 4000);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al eliminar servicio"
          );
          setTimeout(() => this.$router.push({ name: "service" }), 4000);
        });
    },

    async getServices() {
      axios
        .get("/services")
        .then((response) => {
          console.log(response.data);
          this.services = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener servicios"
          );
        });
    },
  },
};
</script>